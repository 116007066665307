const { enableNotice, cookiesList, consentGiven, consentDeclined } = wkConsentPluginPlus;
const cookieName = "wk_consent_plus";

// Initialize dataLayer if not already initialized
window.dataLayer = window.dataLayer || [];

(($) => {
  if (enableNotice === "1") {
    // select all containers
    const $overlay = $(".wk-consent-plus__overlay");
    const $banner = $(".wk-consent-plus__banner");
    const $settings = $(".wk-consent-plus__settings");

    // select all buttons
    const $bannerAccept = $(
      ".wk-consent-plus__banner__content__buttons__button--accept"
    );

    const $bannerDecline = $(
      ".wk-consent-plus__banner__content__buttons__button--decline"
    );

    const $bannerSettings = $(
      ".wk-consent-plus__banner__content__buttons__button--settings"
    );

    const $settingsClose = $(".wk-consent-plus__settings__close");
    const $settingsButton = $(".wk-consent-plus__button");
    const $settingsSave = $("#consent_settings__submit");

    // Function to get cookie value
    const getCookie = (name) => {
      const value = "; " + document.cookie;
      const parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    // Function to push event to dataLayer
    const pushConsentEvent = () => {
      const cookieValue = getCookie(cookieName);

      if (cookieValue === "all" || cookieValue === "marketing") {
        window.dataLayer.push({ event: "consentGiven" });
      } else {
        window.dataLayer.push({ event: "consentDeclined" });
      }
    };

    // Call pushConsentEvent on page load
    $(document).ready(() => {
      pushConsentEvent();
    });

    // check if cookie exists and if it's set
    if (document.cookie.indexOf(cookieName) >= 0) {
      // hide overlay
      $overlay.hide();
      $banner.hide();
      $settingsButton.show();

      // enable scrolling
      $("body").css("overflow", "auto");
    } else {
      // show overlay
      $overlay.show();
      $banner.show();
      $settingsButton.hide();

      // disable scrolling
      $("body").css("overflow", "hidden");
    }

    // banner accept button sets cookie to all and hides banner
    $bannerAccept.click(() => {
      // set cookie
      document.cookie = `${cookieName}=all;max-age=31536000;path=/`;

      // hide overlay
      $overlay.hide();
      $banner.hide();
      $settingsButton.show();

      // enable scrolling
      $("body").css("overflow", "auto");

      // reload page
      window.location.reload();
    });

    // banner decline button sets cookie to necessary and hides banner
    $bannerDecline.click(() => {
      // set cookie
      document.cookie = `${cookieName}=necessary;max-age=31536000;path=/`;

      // hide overlay
      $overlay.hide();
      $banner.hide();
      $settingsButton.show();

      // enable scrolling
      $("body").css("overflow", "auto");

      // reload page
      window.location.reload();
    });

    // banner settings button hides banner and shows settings
    $bannerSettings.click(() => {
      // hide banner
      $banner.hide();

      // show settings
      $settings.show();
      $overlay.show();

      setCheckboxes();

      // disable scrolling
      $("body").css("overflow", "hidden");
    });

    // settings button sets opens settings and hides button
    $settingsButton.click(() => {
      // hide button
      $settingsButton.hide();

      // show settings
      $settings.show();
      $overlay.show();

      setCheckboxes();

      // disable scrolling
      $("body").css("overflow", "hidden");
    });

    // settings close button hides settings and shows banner if no cookie is set else hides settings and shows button
    $settingsClose.click(() => {
      // check if cookie exists and if it's set
      if (document.cookie.indexOf(cookieName) >= 0) {
        // hide settings
        $settings.hide();
        $settingsButton.show();
        $overlay.hide();

        // disable scrolling
        $("body").css("overflow", "auto");
      } else {
        // hide settings
        $settings.hide();

        // show banner
        $banner.show();
        $overlay.show();

        // disable scrolling
        $("body").css("overflow", "hidden");
      }
    });

    // function to set checkboxes based on cookie value
    const setCheckboxes = () => {
      // check if cookie exists and if it's set
      if (document.cookie.indexOf(cookieName) >= 0) {
        // get cookie value
        const cookieValue = document.cookie
          .split("; ")
          .find((row) => row.startsWith(cookieName))
          .split("=")[1];

        // check if cookie value is all, functional or necessary
        if (cookieValue === "all") {
          // set all checkboxes to checked
          $(".wk-consent-plus__settings__content__checkbox input").prop(
            "checked",
            true
          );
        } else if (cookieValue === "functional") {
          // set functional checkbox to checked and uncheck marketing checkbox
          $("#consent_settings__functional").prop("checked", true);
          $("#consent_settings__marketing").prop("checked", false);
        } else if (cookieValue === "marketing") {
          // set marketing checkbox to checked and uncheck functional checkbox
          $("#consent_settings__functional").prop("checked", false);
          $("#consent_settings__marketing").prop("checked", true);
        } else if (cookieValue === "necessary") {
          // uncheck functional and marketing setCheckboxes
          $("#consent_settings__functional").prop("checked", false);
          $("#consent_settings__marketing").prop("checked", false);
        }
      }
    };

    // function to set cookie based on checkbox value
    const setCookie = () => {
      // get checkboxes values
      const functional = $("#consent_settings__functional").is(":checked");
      const marketing = $("#consent_settings__marketing").is(":checked");

      // check if functional and marketing are checked and set cookie to all
      if (functional && marketing) {
        // set cookie
        document.cookie = `${cookieName}=all;max-age=31536000;path=/`;
      } else if (functional && !marketing) {
        // set cookie
        document.cookie = `${cookieName}=functional;max-age=31536000;path=/`;
      } else if (!functional && marketing) {
        // set cookie
        document.cookie = `${cookieName}=marketing;max-age=31536000;path=/`;
      } else if (!functional && !marketing) {
        // set cookie
        document.cookie = `${cookieName}=necessary;max-age=31536000;path=/`;
      }

      // hide settings
      $settings.hide();

      // hide overlay
      $overlay.hide();

      // enable scrolling
      $("body").css("overflow", "auto");

      // show button
      $settingsButton.show();
    };

    // on settingsSave click prevent form from submitting and call setCookie function
    $settingsSave.click((e) => {
      // prevent default action
      e.preventDefault();
      setCookie();
      window.location.reload();
    });

    // remove google cookies if wk_consent_plus cookie is set to necessary of functional
    const cookieValue = getCookie(cookieName);

    const removeCookies = (cookieValue) => {
      const gaCookies = cookiesList;

      const allCookies = document.cookie.split(";");

      allCookies.forEach((cookieString) => {
        const cookieName = cookieString.split("=")[0].trim();

        if (gaCookies.length > 0) {
          gaCookies.forEach((gaCookie) => {
            if (
              cookieValue !== gaCookie["wkcpp_cookie_type"] &&
              cookieValue !== "all"
            ) {
              if (cookieName.includes(gaCookie["wkcpp_cookie_name"])) {
                document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname.replace(
                  "www",
                  ""
                )}`;
              }
            }
          });
        }

      });
    };

    if (cookieValue !== "all") {
      removeCookies(cookieValue);
    }

    // check if cookie exists and if it's set and push dataLayer event based on cookie value
    if (document.cookie.indexOf(cookieName) >= 0) {
      const cookieValue = document.cookie
        .split("; ")
        .find((row) => row.startsWith(cookieName))
        .split("=")[1];

      if (cookieValue === "all") { // all
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: consentGiven,
        });
      } else if (cookieValue === "necessary") { // necessary
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: consentDeclined,
        });
      } else if (cookieValue === "functional") {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: consentDeclined,
        });
      } else if (cookieValue === "marketing") {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: consentGiven,
        });
      }
    } else {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: consentDeclined,
      });
    }
  }
})(window.jQuery);
